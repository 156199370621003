import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../ImageMeta"
import ButtonGroup from "@components/Button/ButtonGroup"
import Button from "@components/Button/Button"

var classNames = require("classnames")
var showdown = require("showdown")

function createHtml(html) {
  return { __html: html }
}

const converter = new showdown.Converter()

function ColumnsWrapper(props) {
  return (
    <div className="columns">
      {!props.isFullWidth && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
      <div className="column">{props.children}</div>
      {!props.isFullWidth && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
    </div>
  )
}

function Heading(props) {
  switch (props.headingType) {
    case "h1":
      return (
        <h1
          style={{
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom
          }}
          dangerouslySetInnerHTML={createHtml(props.heading)}></h1>
      )
    case "h2":
      return (
        <h2
          style={{
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom
          }}
          dangerouslySetInnerHTML={createHtml(props.heading)}></h2>
      )
    case "h3":
      return (
        <h3
          style={{
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom
          }}
          dangerouslySetInnerHTML={createHtml(props.heading)}></h3>
      )
    case "h4":
      return (
        <h4
          style={{
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom
          }}
          dangerouslySetInnerHTML={createHtml(props.heading)}></h4>
      )
    case "h5":
      return (
        <h5
          style={{
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom
          }}
          dangerouslySetInnerHTML={createHtml(props.heading)}></h5>
      )
    case "h6":
      return (
        <h6
          style={{
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom
          }}
          dangerouslySetInnerHTML={createHtml(props.heading)}></h6>
      )
    case "h7":
      return (
        <h7
          style={{
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom
          }}
          dangerouslySetInnerHTML={createHtml(props.heading)}></h7>
      )
  }
}

function SectionImage(props) {
  return (
    <ImageMeta
      cloudName="nuvolum"
      publicId={props.mainPhotoPublicId}
      width="auto"
      responsive
      responsiveUseBreakpoints="true"
    />
  )
}

function Paragraphs(props) {
  return (
    <div
      style={{
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom
      }}
      dangerouslySetInnerHTML={createHtml(
        converter.makeHtml(props.textSection)
      )}></div>
  )
}

class GenericSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const converter = new showdown.Converter()

    if (this.props.genericSection) {
      var sectionColumns = this.props.genericSection.map(section => {
        // console.log(section)
        switch (section.type) {
          case "heading":
            return <Heading {...section} />
          case "sectionImage":
            return <SectionImage {...section} />
          case "paragraphs":
            return <Paragraphs {...section} />
          case "buttons":
            var buttons = section.buttons.map(buttons => (
              <Button key={buttons.button.href} {...buttons.button} />
            ))
            return <ButtonGroup {...section}>{buttons}</ButtonGroup>
        }
      })
    }

    return (
      <section
        className="body-sections section generic-section"
        style={{
          paddingTop: this.props.paddingTop ? this.props.paddingTop : null,
          paddingBottom: this.props.paddingBottom
            ? this.props.paddingBottom
            : null
        }}>
        <ColumnsWrapper {...this.props}>{sectionColumns}</ColumnsWrapper>
      </section>
    )
  }
}

GenericSection.propTypes = {
  headingSideColumnIs: PropTypes.number,
  isMediaFullWidth: PropTypes.bool,
  videoSideColumnIs: PropTypes.number,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  youtube: PropTypes.string,
  youtubePhotoPublicId: PropTypes.string
}

export default GenericSection
