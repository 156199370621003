import React, { Component } from "react"
import Button from "@components/Button/Button"
import PropTypes from "prop-types"
import { getMonthYearFromStrings } from "../../utils/utils"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ImageMeta from "../ImageMeta"

var classNames = require("classnames")

function FacewallImage(props) {
  return (
    <>
      <div className="column fixed-facewall-item has-text-centered">
        <Link to={`/${props.url.toLowerCase()}/`}>
          <ImageMeta
            cloudName="nuvolum"
            publicId={props.imageUrl}
            width="auto"
            responsive
            responsiveUseBreakpoints="true"></ImageMeta>
        </Link>
        <Link
          className="image-caption-link"
          to={`/${props.url.toLowerCase()}/`}>
          <div className="image-caption-wrapper">
            <h6 className="image-caption staff fixed-facewall-name">
              Meet {props.reviewerName}
            </h6>
            <h6 className="image-caption staff staff-title light fixed-facewall-time">
              {getMonthYearFromStrings(
                props.monthYear.year,
                props.monthYear.month
              )}
            </h6>
          </div>
        </Link>
      </div>
      <div className={`column is-${props.middleColumnsis}`}></div>
    </>
  )
}

class FixedFacewall extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const images = this.props.reviews.map(review => {
      return (
        <FacewallImage
          key={review.title}
          imageUrl={review.thumbnailPublicId}
          reviewerName={review.reviewerName}
          url={review.title}
          monthYear={review.monthYear}
          middleColumnsis={this.props.middleColumnsis}
        />
      )
    })

    return (
      <section
        className={classNames(
          "body-sections section fixed-facewall",
          this.props.className
        )}
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column">
            <h3>{this.props.heading}</h3>
            {this.props.blurb}
          </div>
          <div className="column is-2"></div>
        </div>
        <div className="columns procedure-patient-row">
          <div className={`column is-${this.props.sideColumnIs}`}></div>
          {images}
          <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
        <div className="columns has-text-centered">
          <div className="column">
            <Button buttonText="More Patients" href="/patient-stories/" />
          </div>
        </div>
      </section>
    )
  }
}

FixedFacewall.propTypes = {
  reviews: PropTypes.array,
  middleColumnsis: PropTypes.number,
  sideColumnIs: PropTypes.number,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  heading: PropTypes.string,
  blurb: PropTypes.element
}

export default FixedFacewall
